
  import { Component, Vue } from 'vue-property-decorator'
  import DialogContent from '@/components/core/baseDialog/DialogContent.vue'

@Component({
  components: { DialogContent },
})
  export default class GenioAlert extends Vue {
  selected: number[] = [];
  src = require('@/assets/genio/Genio Alert.svg');
  }
